<template>
  <div class="bbb-pages">
    <div class="bbb-how-to" :class="{ 'vn-bg': isVnSource }">
      <router-link :to="url_next" class="close-button">
        <img src="/static/img/big-bang-boom/close-bbb-store.svg" width="100" @click="postTracker('close')" />
      </router-link>

      <template v-if="isVnSource">
        <div class="sbs-page">
          <img src="/static/img/soco-beauty-star/logo-sbs-2-store.png" style="padding-left: 20px" />
          <p>
            Thu thập càng nhiều mặt hàng càng tốt <br />
            vào giỏ. Xem ra cho các cuộc <br />
            tấn công của các ngôi sao!
          </p>
          <a
            v-if="loadingBeautyAtmData"
            href=""
            :disabled="loadingBeautyAtmData"
            tag="button"
            style="background-color: #000"
            >bắt đầu chơi</a
          >
          <router-link
            v-else
            to="/vending-machine-vn/pimple-pop/start?isvn=true"
            tag="button"
            style="background-color: #000"
            >bắt đầu chơi</router-link
          >
        </div>
        <!-- <img class="how-to-play" src="/static/img/soco-beauty-star/sbs-how-to-play.png" /> -->
      </template>
      <template v-else>
        <div class="sbs-page">
          <img src="/static/img/soco-beauty-star/logo-sbs-2-store.png" style="padding-left: 20px" />
          <!-- <p>
            Kumpulkan beauties<br />
            sebanyak mungkin kedalam keranjang.<br />
            Hati-hati hindari barang pecah!
          </p> -->
          <p>
            Kumpulkan beauties sebanyak mungkin <br />
            kedalam keranjang. Hati-hati kena <br />
            serangan membandel!
          </p>
          <a v-if="loadingBeautyAtmData || !beautyAtmData" href="" disabled tag="button" style="background-color: #000"
            >MAIN SEKARANG</a
          >
          <router-link v-else to="/vending-machine/pimple-pop/start" tag="button" style="background-color: #000"
            >MAIN SEKARANG</router-link
          >
        </div>
        <!-- <img class="how-to-play" src="/static/img/soco-beauty-star/sbs-how-to-play.png" /> -->
      </template>
      <div v-if="products.length || noteLists.length" class="section-product">
        <img class="bg-spark how-to-play" src="/static/img/soco-beauty-star/bg-spark.png" />
        <div class="list-product">
          <div v-for="(product, key) in products" :key="`list-product-${key}`" class="item">
            <div class="item-wrapper" :class="product.type">
              <img :src="product.image" :alt="product.brand_initiate" class="product-thumb" />
              <div class="item-detail">
                <h4>{{ product.brand_initiate }}</h4>
                <p>{{ product.point > 0 ? `+${product.point}` : product.point }} Points</p>
              </div>
            </div>
          </div>
        </div>
        <div v-if="noteLists.length" class="notes">
          <div class="header">
            <span>Jangan lupa</span>
          </div>
          <ul class="list">
            <li v-for="(note, key) in noteLists" :key="`list-note-${key}`">
              Kumpulkan min. {{ note.min_point }} poin untuk diskon {{ note.percentage }}%
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VendingMachineTracker from '@/mixins/vending-machine-tracker';
import autotimeout from '@/mixins/auto-timeout';

import { userActions } from '@/helpers/user-actions';

export default {
  name: 'SocoBeautyStarHowToPlay',
  mixins: [VendingMachineTracker, autotimeout],
  computed: {
    isVnSource() {
      return this.$route.query.isvn;
    },
    url_next() {
      if (this.$route.query.isvn) {
        return `/vending-machine-vn/home?isvn=true`;
      } else {
        const path = Cookies.get('vending_machine_device') == 'true' ? 'home' : 'login';
        return '/vending-machine/' + path;
      }
    },
    beautyAtmData() {
      return this.$store.state.beautyAtmData;
    },
    loadingBeautyAtmData() {
      return this.$store.state.loadingBeautyAtmData;
    },
    products() {
      if (this.beautyAtmData?.products?.length) {
        return this.beautyAtmData.products;
      }
      return [];
    },
    vouchers() {
      if (this.beautyAtmData?.vouchers?.length) {
        return this.beautyAtmData.vouchers;
      }
      return [];
    },
    noteLists() {
      let result = [];
      if (this.vouchers?.length) {
        result = this.vouchers.filter((item) => item.reward_type !== 'no-reward');
      }
      return result;
    },
  },
  created() {
    if (!this.beautyAtmData) {
      this.getBeautyAtmData();
    }
  },
  mounted() {
    userActions(this);
  },
  methods: {
    getBeautyAtmData() {
      this.$store.dispatch('getBeautyAtmData');
    },
  },
};
</script>

<style lang="scss" scoped>
@import './src/assets/sass/_mixin-revamp.scss';
.bbb-how-to {
  min-height: 100vh;
  background: #000 url('/static/img/soco-beauty-star/bg-sbs-play.png') no-repeat top center;
  background-size: 100% auto;
  position: relative;
}
.vn-bg {
  min-height: 100vh;
  background: #000 url('/static/img/soco-beauty-star/bg-sbs-play.png') no-repeat top center;
  background-size: 100% auto;
  position: relative;
}

.title {
  margin: 0 auto 57px;
  display: block;
}

button {
  display: block;
  width: 685px;
  height: 98px;
  margin: 50px auto 60px;
  border-radius: 48px;
  font: normal 32px 'brandontext-bold', sans-serif;
  letter-spacing: 3.2px;
  color: #fff;
  background: #d51d6e;
  border: 0;
}
button:focus {
  outline: none;
}
.close-button {
  display: block;
  width: 100px;
  height: 100px;
  position: absolute;
  top: 50px;
  right: 50px;
  z-index: 2;
}

.sbs-page {
  text-align: center;
  padding: 120px 0 10px 0;
}

.sbs-page p {
  font: normal 44px/48px 'brandontext-bold', sans-serif;
  letter-spacing: 1px;
  color: #b81048;
}

.section-product {
  position: relative;
  padding: 120px 0 78px;
  background: #ffc1d7;
  @include box-shadow(
    6px 6px 12px 0 #f297bd,
    inset -10px -5px 10px 0 rgba(205, 92, 150, 0.3),
    inset 9px 9px 9px 0 rgba(255, 255, 255, 0.5)
  );
  @include border-radius(50px 50px 0 0);
  .bg-spark {
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    height: calc(100% - 60px);
    object-fit: cover;
  }
  .list-product {
    display: flex;
    flex-wrap: wrap;
    max-width: 650px;
    position: relative;
    margin: 0 auto;
  }
  .item {
    width: 50%;
    margin: 47px 0;
  }
  .item-wrapper {
    max-width: 265px;
    width: 100%;
    margin: 0 auto;
    text-align: center;
    position: relative;
    &.type-a .product-thumb {
      max-width: 152px;
      max-height: 154px;
      top: -84px;
    }
    &.type-b .product-thumb {
      max-width: 152px;
      max-height: 154px;
      top: -84px;
    }
    &.broken-heart .product-thumb {
      max-width: 129px;
      max-height: 106px;
      top: -46px;
    }
    &.broken-star .product-thumb {
      max-width: 140px;
      max-height: 133px;
      top: -70px;
    }
  }
  .item-detail {
    position: relative;
    background: #ffdbe4;
    @include box-shadow(
      3px 3px 7px 0 #f297bd,
      inset -5px -4px 9px 0 rgba(205, 92, 150, 0.28),
      inset 1px 1px 2px 0 rgba(255, 255, 255, 0.5)
    );
    @include border-radius(27.42px);
    padding: 84px 0 35px;
    h4 {
      font: 38px/53px $brandon-bold;
      letter-spacing: normal;
      color: #000000;
      margin: 0;
    }
    p {
      color: #bf0256;
      font: 44px/61px $brandon-medium;
      margin: 0;
      font-weight: bold;
    }
  }
  .product-thumb {
    width: 100%;
    height: 100%;
    object-fit: cover;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
    margin: 0 auto;
    position: absolute;
  }
  .notes {
    margin: 25px auto 0;
    max-width: 826px;
    width: 100%;
    .header {
      padding: 33px 37px;
      background: #ff8db1;
      @include box-shadow(
        5px 5px 5px 0 rgba(162, 20, 80, 0.23),
        inset -4px -5px 8px 0 rgba(126, 1, 56, 0.29),
        inset 5px 5px 6px 0 rgba(255, 255, 255, 0.72)
      );
      @include border-radius(27px 27px 0px 0px);
      display: inline-block;
      span {
        text-transform: uppercase;
        color: #ffffff;
        letter-spacing: 2.1px;
        font: 36px/42px $brandon-bold;
      }
    }
    .list {
      width: 100%;
      padding: 36px 69px;
      background: #ce3378;
      @include box-shadow(
        5px 4px 7px 0 rgba(78, 42, 57, 0.34),
        inset -4px -5px 8px 0 rgba(126, 1, 56, 0.48),
        inset 5px 5px 6px 0 rgba(255, 255, 255, 0.45)
      );
      @include border-radius(27px);
      margin: -18px 0 0;
      z-index: 1;
      position: relative;
    }
    .list li {
      width: 100%;
      color: #ffffff;
      letter-spacing: 0px;
      font: 36px/50px $brandon-bold;
      display: block;
    }
  }
}

.how-to-play {
  width: 100%;
  left: 0;
  right: 0;
}
</style>
